<template>
  <div v-if="ready">
    <!-- <ProgressBar :value="bar"/> -->
    <!-- <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-cbd5e1e0" class="div-precedent" v-if="souscription.last_step > 1">
    <a @click="previous()" class="zone-precedent w-inline-block"></a>
  </div> -->
  <transition name="fade">
    <component :is="getStep()" :souscription="souscription" :user="user" :proprietaire="proprietaire" :params="params"></component>
  </transition>
</div>
</template>

<script>
//import ProgressBar from '@/components/ProgressBar'
import Step1 from './Step1';
import FStep2 from './Final/Step2';
import FStep3 from './Final/Step3';
import FStep4 from './Final/Step4';
import FStep5 from './Final/Step5';
import FStep6 from './Final/Step6';
import FStep7 from './Final/Step7';
import FStep8 from './Final/Step8';
import FStep9 from './Final/Step9';
import FStep10 from './Final/Step10';
import FStep11 from './Final/Step11';
import AStep2 from './Attestation/Step2';
import AStep3 from './Attestation/Step3';
import AStep4 from './Attestation/Step4';
import AStep5 from './Attestation/Step5';
import AStep6 from './Attestation/Step6';
import AStep7 from './Attestation/Step7';
import AStep8 from './Attestation/Step8';
import AStep9 from './Attestation/Step9';
import AStep10 from './Attestation/Step10';
import AStep11 from './Attestation/Step11';
import AStep12 from './Attestation/Step12';
import AStep13 from './Attestation/Step13'; 

import V2Step1 from '../v2-zero-depot/Step1.vue'; 
import V2Step2 from '../v2-zero-depot/Step2.vue'; 
import V2Step3 from '../v2-zero-depot/Step3.vue'; 
import V2Step4 from '../v2-zero-depot/Step4.vue'; 
import V2Step5 from '../v2-zero-depot/Step5.vue'; 
import V2Step6 from '../v2-zero-depot/Step6.vue'; 
import V2Step7 from '../v2-zero-depot/Step7.vue'; 
import V2Step8 from '../v2-zero-depot/Step8.vue'; 

export default {
  name: "ZeroDepot",
  components: {
    Step1, FStep2, FStep3, FStep4, FStep5, FStep6, FStep7, FStep8, FStep9, FStep10, FStep11, AStep2, AStep3, AStep4, AStep5, AStep6, AStep7, AStep8, AStep9, AStep10, AStep11, AStep12, AStep13,
    V2Step1, V2Step2, V2Step3, V2Step4, V2Step5, V2Step6, V2Step7, V2Step8
  },
  data(){
    return {
      ready:false,
      bar:5,
      params:false,
      user:{
        id:null,
        email:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        telephone:null,
        password:null
      },
      souscription:{
        id:null,
        user_id:null,
        provisoir:null,
        loyer:null,
        meublee:false,
        logement:{
          adresse:null,
          adresse2:null,
          cp:null,
          ville:null,
          pays:"France",
          date_bail:null
        },
        proprietaire_id:null,
        coup_dur:null,
        last_step:1,
        paiement:{
          periode:null,
          type_id:null,
          stripe_cb_id:null
        },
        api_key:null,
        api_type:null,
        apt_ref: null,
        v: 2
      },
      proprietaire:{
        nom:null,
        email:null,
        telephone:null
      },      
      flag:false
    }
  },
  methods: {
    getStep() {
      var name = null;
      if(this.souscription.v === 2){
        name = "V2Step"
      }else{
        if(this.souscription.last_step === 1){
          name = "Step";
        }else{
          name = this.souscription.provisoir === false ? "FStep" : "AStep";
        }
      }      
      name = name+this.souscription.last_step;
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.flag = false;
      },500)
      return name;
    },
    next(){
      if(this.flag === true){
        return false;
      }
      this.flag = true;
      this.souscription.last_step = this.souscription.last_step + 1;
    },
    previous(val = false){
      if(val === false){
        val = this.souscription.last_step - 1;
      }
      this.souscription.last_step = val;
      console.log(val);      
    }
  },
  beforeMount(){
    if(this.$route.query.uuid != undefined){
      this.$store.api.ajax('/zero-depot/get/'+this.$route.query.uuid, null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.souscription = res.data.souscription;
          if(res.data.proprietaire != false){
            this.proprietaire = res.data.proprietaire;
          }
          this.next();
          this.$root.$emit('userData', this.user);
        }
      })
    }
    if(this.$route.query.loyer != undefined && this.$route.query.meublee != undefined){
      var loyer = parseInt(this.$route.query.loyer)
      this.souscription.meublee = this.$route.query.meublee == 'true' ? true : false;
      if(!isNaN(loyer)){
        this.souscription.loyer = loyer
        this.souscription.last_step = 2;
      }      
    }
    this.$store.api.ajax('/zero-depot/params', null, (res) => {
      if(res.status === true){
        this.params = res.data
        this.ready = true;
      }
    });
    if(this.$route.query.key != undefined){
      this.souscription.api_key = this.$route.query.key;
      this.souscription.api_type = parseInt(this.$route.query.type);
    }
    if(this.$route.query.loyer != undefined){
      this.souscription.loyer = parseFloat(this.$route.query.loyer);
    }
    if(this.$route.query.meuble != undefined){
      this.souscription.meublee = this.$route.query.meuble === 'non' ? false : true;
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-form";
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].className = "body-crm"
  }
};
</script>
<style>
.btn-no-valide {
  background: #E0E0E0 !important;
}
/*! CSS Used from: https://pautions.fr/css/normalize.css */
/*! CSS Used from: https://pautions.fr/css/components.css */

.form_calculateur {
  padding-top: 7px;
  padding-bottom: 0px;
  padding-left: 20px;
  border-radius: 15px;
  font-family: Hind, sans-serif;
  text-align: left;
}
.form_calculateur.v2 {
  margin-bottom: 0px;
  padding-top: 6px;
  background-image: url("https://pautions.fr/images/ic_euro.svg");
  background-position: 92% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}
@media screen and (max-width: 479px) {
  .form_calculateur {
    font-size: 30px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
